<template>
  <div class="section container">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link to="/admin/cursos">Curso: {{breadcrumb.course}}</router-link>
        </li>
        <li>
          <router-link
            :to="{name: 'Módulos', params:{ name: $route.params.cur }}"
          >{{breadcrumb.module}}</router-link>
        </li>
        <li class="is-active">
          <a href="#" aria-current="page">Lições</a>
        </li>
      </ul>
    </nav>
    <button class="button m-b-md" @click="activeModal">Adicionar</button>

    <b-table
      :data="data"
      :columns="columns"
      :paginated="true"
      per-page="10"
      current-page.sync="1"
      :pagination-simple="false"
      default-sort-direction="asc"
      default-sort="name"
      aria-next-label="Próxima pagina"
      aria-previous-label="Pagina anterior"
      aria-page-label="Page"
      aria-current-label="Pagina atual"
      detailed
      :opened-detailed="[1]"
      detail-key="_id"
      :show-detail-icon="true"
    >
      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <b-button class="m-r-sm" type="is-primary" @click="edit(props)">Editar</b-button>

              <router-link
                class="button is-link m-r-sm"
                :to="{name: 'Questão', params:{ name: props.row._id, mod: $route.params.name, cur: $route.params.cur}}"
              >Questões</router-link>

              <b-button type="is-danger" @click="confirm(props)">Remover</b-button>
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <Modal
      :inputs="inputs"
      :active="showModal"
      :clear="clear"
      @functionByModal="addlesson"
      @close="showModal = false"
    ></Modal>
    <Modal
      :inputs="editLesson"
      :active="showModal2"
      :clear="clear"
      @functionByModal="update"
      @close="showModal2 = false"
    ></Modal>
  </div>
</template>

<script>
import Axios from "axios";

import Modal from "@/components/widgets/Modal";
export default {
  components: { Modal },
  data() {
    return {
      breadcrumb: {},
      showModal: false,
      showModal2: false,
      clear: false,
      columns: [
        {
          field: "name",
          label: "Nome",
          sortable: true
        },
        {
          field: "updatedAt",
          label: "Última alteração",
          sortable: true
        }
      ],
      inputs: [
        {
          label: "Título",
          type: "text",
          model: "name",
          required: true
        },
        {
          label: "Conteúdo",
          type: "textarea",
          model: "description"
        },
        {
          label: "URL do video(vimeo)",
          type: "text",
          model: "videoUrl"
        },
        {
          label: "URL do PODCAST(vimeo)",
          type: "text",
          model: "extra.podcast"
        },
        {
          label: "Apostila",
          type: "text",
          model: "extra.apostila"
        },
        {
          label: "Limite de vezes para fazer o exame",
          type: "number",
          model: "examLimit"
        },
        {
          label: "Menssagem do limite de vezes para fazer o exame",
          type: "text",
          model: "examLimitMessage"
        },
        {
          label: "Duração em minutos",
          type: "text",
          model: "hourlyLoad"
        },
        {
          label: "Requer",
          type: "select",
          model: "requirement"
        },
        {
          label: "Tempo do exame em minutos",
          type: "number",
          model: "examTime"
        },
        {
          label: "Porcentagem mínima para aprovação.",
          type: "number",
          model: "totalToApprove"
        },
        {
          label: "Quantidade de questões para a prova",
          type: "number",
          model: "amountQuestion"
        },
        {
          label: "Ordenação",
          type: "number",
          model: "order"
        },
        {
          label: "Lição Final",
          type: "checkbox",
          model: "finalLesson"
        }
      ],
      data: [],
      editLesson: []
    };
  },
  methods: {
    edit(data) {
      if(!data.row.extra) {
        data.row.extra = {
          podcast: null,
          apostila: null
        }
      }

      this.editLesson = [
        {
          label: "Título",
          type: "text",
          model: "name",
          name: data.row.name,
          _id: data.row._id,
          required: true
        },
        {
          label: "Conteúdo",
          type: "textarea",
          model: "description",
          description: data.row.description
        },
        {
          label: "URL do video(vimeo)",
          type: "text",
          model: "videoUrl",
          videoUrl: data.row.videoUrl
        },
        {
          label: "URL do PODCAST(vimeo)",
          type: "text",
          model: "extra.podcast",
          "extra.podcast": data.row.extra.podcast
        },
        {
          label: "Apostila",
          type: "file",
          model: "extra.apostila",
          "extra.apostila": data.row.extra.apostila
        },
        {
          label: "Limite de vezes para fazer o exame",
          type: "number",
          examLimit: data.row.examLimit,
          model: "examLimit"
        },
        {
          label: "Menssagem do limite de vezes para fazer o exame",
          type: "text",
          examLimitMessage: data.row.examLimitMessage,
          model: "examLimitMessage"
        },
        {
          label: "Duração em minutos",
          type: "text",
          model: "hourlyLoad",
          hourlyLoad: data.row.hourlyLoad
        },
        {
          label: "Requer",
          type: "select",
          model: "requirement",
          content: this.data,
          requirement: data.row.requirement
        },
        {
          label: "Tempo do exame em minutos",
          type: "number",
          model: "examTime",
          examTime: data.row.examTime
        },
        {
          label: "Porcentagem mínima para aprovação.",
          type: "number",
          model: "totalToApprove",
          totalToApprove: data.row.totalToApprove
        },
        {
          label: "Quantidade de questões para a prova",
          type: "number",
          model: "amountQuestion",
          amountQuestion: data.row.amountQuestion,
        },
        {
          label: "Ordenação",
          type: "number",
          model: "order",
          order: data.row.order,
        },
        {
          label: "Lição Final",
          type: "checkbox",
          model: "finalLesson",
          finalLesson: data.row.finalLesson
        }
      ];
      this.showModal2 = true;
    },
    activeModal() {
      this.inputs[6].content = null;
      this.inputs[8].content = this.data;
      this.showModal = true;
    },
    update(data) {
      let dados = Object.assign({}, data);
      dados = this.toNumber(dados);
      Axios.put(`/v1/lessons/${this.editLesson[0]._id}`, dados)
        .then(data => {
          if (data.data) {
            this.getLesson();
            this.showModal2 = false;
            this.$buefy.notification.open({
              message: "Dados atualizados com sucesso!",
              type: "is-success"
            });
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    remove(data) {
      Axios.delete(`/v1/lessons/${data.row._id}`)
        .then(data => {
          if (data.data) {
            this.getLesson();
            this.$buefy.notification.open({
              message: "Curso excluido com sucesso!",
              type: "is-success"
            });
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    addlesson(data) {
      let dados = Object.assign({}, data);
      dados.module = this.$route.params.name;
      dados.course = this.$route.params.cur;
      dados = this.toNumber(dados);
      Axios.post(`/v1/lessons/`, dados)
        .then(data => {
          if (data.data) {
            this.getLesson();
            this.showModal = false;
            this.$buefy.notification.open({
              message: "Modulo criado com sucesso!",
              type: "is-success"
            });
            this.clear = !this.clear;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getLesson() {
      Axios.get(`/v1/lessons?module=${this.$route.params.name}`)
        .then(data => {
          if (data.data) {
            this.data = data.data.data;
            this.inputs[4].content = data.data.data;
            data.data.data.map((item, index) => {
              this.data[index].updatedAt = new Date(
                item.updatedAt
              ).toLocaleDateString("pt-BR");
            });
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    confirm(data) {
      this.$buefy.dialog.confirm({
        message: "Tem certeza que deseja <b>excluir</b> este modulo?",
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.remove(data)
      });
    },
    getCourse() {
      Axios.get(`/v1/courses/${this.$route.params.cur}`)
        .then(data => {
          if (data.data) {
            this.breadcrumb.course = data.data.name;
            this.$forceUpdate(this.breadcrumb);
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getModulo() {
      Axios.get(`/v1/modules/${this.$route.params.name}`)
        .then(data => {
          if (data.data) {
            this.breadcrumb.module = data.data.name;
            this.$forceUpdate(this.breadcrumb);
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    toNumber(item) {
      let dados = item;
      dados.order = dados.order * 1;
      dados.hourlyLoad = dados.hourlyLoad * 1;
      dados.amountQuestion = dados.amountQuestion * 1;
      dados.totalToApprove = dados.totalToApprove * 1;
      dados.examTime = dados.examTime * 1;
      dados.examLimit = dados.examLimit * 1;
      dados.finalLesson = dados.finalLesson ? dados.finalLesson : false;
      return dados;
    }
  },
  mounted() {
    this.getLesson();
    this.getCourse();
    this.getModulo();
  }
};
</script>

<style>
</style>
